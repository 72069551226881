import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup, LayerGroup, Tooltip } from 'react-leaflet';
import { createBuildingSVG4 } from '../util/building';
import { locations } from '../util/locations';
import React, { useState, useEffect } from 'react';

const windowColor='lightblue'



const createBuildingIcon = (baseColor: string, windowColor: string) => {
  

    return L.divIcon({
        className: '',
        html: createBuildingSVG4(baseColor),
        iconSize: [32, 32],
        iconAnchor: [16, 32],
    });
}

// Add the green icons
const okMarkers: L.Marker<Location>[] = locations.map(loc => {
    const marker=L.marker([loc.lat, loc.lng], { icon: createBuildingIcon(loc.color, windowColor) })
    marker.text=loc.text
    marker.img=loc.img
    marker.issue=loc.issue
    marker.color=loc.color
    return marker}
);

 

const MagnifyerMap: React.FC = () => {
  const [coloredMarkers, setColoredMarkers] = useState<number[]>([]);

  useEffect(() => {
    const totalMarkers =  okMarkers.length;
    const interval = 5000 / totalMarkers;

    // Add a 3-second delay before starting the colorTimer
    const initialDelay = setTimeout(() => {
      const colorTimer = setInterval(() => {
        setColoredMarkers(prev => {
          if (prev.length >= totalMarkers) {
            clearInterval(colorTimer);
            return prev;
          }
          return [...prev, prev.length];
        });
      }, interval);

      // Clean up the interval when the component unmounts
      return () => clearInterval(colorTimer);
    }, 1000);

    // Clean up the initial delay timeout when the component unmounts
    return () => clearTimeout(initialDelay);
  }, []);

  return (
    <MapContainer
      center={[51.405, -0.19]}
      zoom={8}
      zoomControl={false}
      scrollWheelZoom={false}
      doubleClickZoom={false}
      boxZoom={false}
      keyboard={false}
      dragging={false}
      tap={false}
      touchZoom={false}
      zoomSnap={0}
      zoomDelta={0}
      inertia={false}
      style={{ height: '100vh', width: '100%' }}
    >
      <TileLayer
        url='https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYXNtdWVsbGUiLCJhIjoiY2pwMml0bTI1MDduaTN2cnd4Z2dqZWVpdCJ9.ycIptPf7zekWNCNi-bgY7A'
        opacity={0.4}
        id='mapbox/light-v10'
      />
      
      <LayerGroup>
       
       
         {okMarkers.map((marker, index) => (
          <Marker 
            key={`ok-${index}`}
            position={marker.getLatLng()} 
            icon={coloredMarkers.includes(index ) ? createBuildingIcon(marker.color,windowColor) : createBuildingIcon('#808080','lightgray')}
          >
            <Tooltip>{marker.issue} (click for details)</Tooltip>
            <Popup>
            {marker.text}
              <img width="300px"src={marker.img}></img>
            </Popup>
          </Marker>
        ))}
      </LayerGroup>
    </MapContainer>
  );
};

export default MagnifyerMap;

