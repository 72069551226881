import React, { useState } from 'react';
import { Container, Row, Col, Modal, Button, Form, Toast, ToastContainer } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Stack from 'react-bootstrap/Stack';
import CookieConsent, { Cookies } from "react-cookie-consent";
import MagnifyerMap from './components/MagnifyerMap';
import ContactForm from './components/ContactForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

function App() {
  const [showImpressum, setShowImpressum] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const handleClose = () => setShowImpressum(false);
  const handleShow = () => setShowImpressum(true);
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = ( ) => {
    setShowModal(false);
    setShowToast(true);
  };

  const handleModalDismiss = () => {
    setShowModal(false);
  };
  const handleModalShow = () => setShowModal(true);
// Get the button



// When the user clicks on the button, scroll to the top or bottom of the document
const scrollDown = function() {

        window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
}
const scrollUp = function() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }


  return (
    <div>
      <div className="header"> 
          <div style={{padding:'5px'}}><img className="navbar-brand" src="/images/logo_small.svg"/><span style={{padding:'5px', fontSize:'1.2rem', color:'gray'}}>Energy Intelligence for multi-site enterprise clients</span></div>
        </div>
    
      <Stack gap={3}>
        <div className="map">  
      
          <MagnifyerMap />
        
        
        </div>
        <Container fluid style={{ marginTop: '30px', padding: '10px'}}>
          <section className="treatments"  style={{ padding: '10px', backgroundColor: 'white' , borderRadius: '10px' }}>
      <h2 className="section-title" style={{ textAlign: 'center' }}>Identify the problem</h2>
      <Row>
        <Col sm={6} md={3}>
          <div className="icon-box">
            <svg className="icon icon-diamond"><use xlinkHref="#icon-diamond"></use></svg>
            <h4>Trust Data</h4>
            <p>Data spikes, non-comms. and meter rescaling must be exposed</p>
          </div>
        </Col>
        
        <Col sm={6} md={3}>
          <div className="icon-box">
            <svg className="icon icon-calendar"><use xlinkHref="#icon-calendar"></use></svg>
            <h4>Make Time</h4>
            <p>Ranked priority, <br />(by size, scope, duration etc)<br /> on your desk each morning</p>
          </div>
        </Col>
        
        <Col sm={6} md={3}>
          <div className="icon-box">
            <svg className="icon icon-briefcase"><use xlinkHref="#icon-briefcase"></use></svg>
            <h4>Make a case</h4>
            <p>A track-record of success<br /> depends on iron-clad prognosis</p>
          </div>
        </Col>
        
        <Col sm={6} md={3}>
          <div className="icon-box">
            <svg className="icon icon-leaf"><use xlinkHref="#icon-leaf"></use></svg>
            <h4>See the big picture</h4>
            <p>Many sites and many charts mean it is hard to <em>see the wood for the trees</em></p>
          </div>
        </Col>
      </Row>
      </section>
    </Container>
    <Container fluid style={{ marginTop: '10px',padding:'10px' }}>
      <section style={{ padding: '10px', backgroundColor: 'white' , borderRadius: '10px' }}>
      <h2 className="section-title" style={{ textAlign: 'center' }}>Resolve the issue</h2>
      <Row className="treatments" style={{  display: 'flex', flexWrap: 'wrap' }}>
        <Col sm={6} md={3}>
          <div className="icon-box">
            <svg className="icon icon-flask"><use xlinkHref="#icon-flask"></use></svg>
            <h4>Filter &amp; Correct</h4>
            <p>Exclude poor data,<br />Note operational change,<br />Rescale history as necesssary</p>
          </div>
        </Col>
        
        <Col sm={6} md={3}>
          <div className="icon-box">
            <svg className="icon icon-user-md"><use xlinkHref="#icon-user-md"></use></svg>
            <h4>Establish Priority</h4>
            <p>Setting thresholds is a pain!<br />Self-adaptive models<br /> speeds diagnosis</p>
          </div>
        </Col>
        
        <Col sm={6} md={3}>
          <div className="icon-box">
            <svg className="icon icon-trophy"><use xlinkHref="#icon-trophy"></use></svg>
            <h4>Proof-of-savings</h4>
            <p>Before / After contrast<br /> transparent quantification in operational context</p>
          </div>
        </Col>
        
        <Col sm={6} md={3}>
          <div className="icon-box">
            <svg className="icon icon-bolt"><use xlinkHref="#icon-bolt"></use></svg>
            <h4>Drill-down</h4>
            <p>Single-click access from outlier site to detailed diagnosis</p>
          </div>
        </Col>
      </Row>
      </section>
    </Container>
       
      
      </Stack>
      <button
        type="button"
        className="btn btn-primary btn-lg"
        onClick={handleModalShow}
        style={{
          position: 'fixed',
          right: '0px',
          top: '150px',
          zIndex: 1011,
          borderRadius: '10px 0 0 10px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
        }}
        data-toggle="modal"
        data-target="#myModal"
      >
        Request demo
      </button>

      <button
        type="button"
        className="btn btn-primary"
        onClick={scrollDown}
        style={{
          position: 'fixed',
          right: '0px',
          bottom: '15px',
          zIndex: 1011,
          borderRadius: '10px 0 0 10px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
        }}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </button>

      <button
        type="button"
        className="btn btn-primary"
        onClick={scrollUp}
        style={{
          position: 'fixed',
          left: '0px', // Adjusted to accommodate both buttons
          bottom: '15px',
          zIndex: 1011,
          borderRadius: '0 10px 10px 0',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
        }}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </button>
      <Modal 
          show={showImpressum} 
          onHide={handleClose} 
          size="xl"
          style={{ top: '10%'}}
        >
          <Modal.Header closeButton>
            <Modal.Title>Impressum</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <Row>
                <Col sm={12} md={4}>
                  <h4>Kontakt</h4>
                  <div>kWIQly GmbH</div>
                  <div>Untere Gasse 9</div>
                  <div>CH-3853 Niederried, Bern, Switzerland</div>
                  <div>info@kwiqly.com</div>
                  <div><img src="/images/flag_ch.svg" style={{ height: '15px', width: '30px' }} alt="CH flag" /> +41 (0)33 849 1086</div>
                  <div><img src="/images/flag_uk.svg" style={{ height: '15px', width: '30px' }} alt="UK flag" /> +44 (0)20 8720 6826</div>
                </Col>
                <Col sm={12} md={4}>
                  <h4>Stand und Ergänzung</h4>
                  <div>Register: Handelsregisteramt Bern</div>
                  <div>Registernummer: CH-036.4.041.645-6</div>
                </Col>
                <Col sm={12} md={4}>
                  <h4>Vertretungsberechtigte Geschäftsführer</h4>
                  <div>James Ferguson</div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>

      <Modal show={showModal} onHide={handleModalDismiss}>
        <Modal.Header closeButton>
          <Modal.Title>Request Demo or Call</Modal.Title>
        </Modal.Header>
        <ContactForm onClose={handleModalClose} />
      </Modal>
    
      <footer style={{ padding: '5px', marginTop: '10px' }} className="main-footer">
      © 2024 kWIQly <a href="/privacy_policy.pdf">Privacy Policy</a>  <a href="#" onClick={handleShow}>Impressum</a>

    </footer>
    <CookieConsent
  enableDeclineButton
  onDecline={() => {
    
  }}
> This website uses cookies to enhance the user experience.{" "}</CookieConsent>
    <ToastContainer position="top-end" className="p-3">
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
          <Toast.Header>
            <strong className="me-auto">kWIQly</strong>
          </Toast.Header>
          <Toast.Body>Thank you for your interest in kWIQly. We will get back to you soon.</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default App;

